import React from "react"
import { Alert, Button, ButtonGroup, Card, Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import { Exercise, ExerciseCategory, ExerciseDependency, ExerciseType, Game, Workout, WorkoutCategory } from "../../../libs/types";
import { ExercisesSelectionFormGroup } from "./ExercisesSelectionFormGroup";
import { GamesSelectionFormGroup } from "./GameSelectionFormGroup";
import { useFormContext, useWatch } from "react-hook-form";
import { CompoundWorkoutFormValues, convertWorkoutToFormValues, workoutFormSchema, WorkoutFormValues } from "./schema";
import { apiCreateWorkout } from "../../../api/resources/patients/workouts/apiCreateWorkout";
import { apiUpdateWorkout } from "../../../api/resources/patients/workouts/apiUpdateWorkout";
import { Chip } from "../../../libs/shared_components/Chip";
import { RequiredFieldIndicator } from "../../../libs/shared_components/RequiredFieldIndicator";
import { WithRequired } from "../../../libs/utility_types/WithRequired";
import classNames from "classnames";

type WorkoutFormProps = {
  on_behalf_of_patient: boolean,
  patient_id: string,
  position: number,
  workout_forms_count: number,
  games: Game[],
  exercises: Exercise[],
  exercise_types: ExerciseType[],
  workout_categories: WorkoutCategory[],
  exercise_categories: ExerciseCategory[],
  exercise_dependencies: ExerciseDependency[],
  checkNameModified: (position: number, workout_id: string) => boolean,
  checkModified: (position: number, workout_id: string) => boolean,
  onWorkoutSaved: () => Promise<void>,
  onReset: (position: number, workout_id: string) => void,
  onRemove: (position: number) => void,
  onMove: (direction: 'left' | 'right', position: number) => void,
}

export const WorkoutForm = ({
  on_behalf_of_patient,
  patient_id,
  position,
  workout_forms_count,
  games,
  exercises,
  workout_categories,
  exercise_types,
  exercise_categories,
  exercise_dependencies,
  checkNameModified,
  checkModified,
  onWorkoutSaved,
  onRemove,
  onReset,
  onMove,
}: WorkoutFormProps) => {
  const form = useFormContext<CompoundWorkoutFormValues>();
  const workout = useWatch({
    control: form.control,
    name: `workouts.${position}`,
  }) as WorkoutFormValues | undefined;

  const handleSave = async () => {
    if (!workout) {
      return;
    }

    try {
      let saved_workout: Workout;

      if (!isEditing(workout)) {
        // Creating a new workout
        const response = await apiCreateWorkout({
          patient_id: patient_id,
          name: workout.name,
          workout_category_id: workout.category_id,
          duration: workout.duration,
          max_difficulty: workout.difficulty,
          number_of_exercises: workout.number_of_exercises,
          selected_exercises: workout.selected_exercises,
          selected_games_ids: workout.selected_games_ids,
        });

        saved_workout = response.workout;
        alert('Workout saved!');

      } else {
        // Edited an existing workout
        const response = await apiUpdateWorkout({
          id: workout.id,
          patient_id: patient_id,
          name: workout.name,
          workout_category_id: workout.category_id,
          duration: workout.duration,
          max_difficulty: workout.difficulty,
          number_of_exercises: workout.number_of_exercises,
          selected_exercises: workout.selected_exercises,
          selected_games_ids: workout.selected_games_ids,
        });

        saved_workout = response.workout;
        alert('Workout updated!');
      }

      form.setValue(
        `workouts.${position}`,
        convertWorkoutToFormValues(saved_workout),
        { shouldValidate: true, shouldTouch: true }
      );
      await onWorkoutSaved();

    } catch (error) {
      alert('Failed to save: ' + JSON.stringify(error));
    }
  }

  const handleSaveAsCopy = async () => {
    if (!workout) {
      return;
    }

    let name = workout.name;

    if (isEditing(workout)) {
      const not_modified_name = !checkNameModified(position, workout.id);
      if (not_modified_name) {
        // If copying the workout and its name was not modified, append (copy) into it so its easier to identify later.
        // - The user can edit this afterwards.
        name = `${name} (copy)`;
      }
    }

    try {
      // Creating a new workout
      const response = await apiCreateWorkout({
        patient_id: patient_id,
        name: name,
        workout_category_id: workout.category_id,
        duration: workout.duration,
        max_difficulty: workout.difficulty,
        number_of_exercises: workout.number_of_exercises,
        selected_exercises: workout.selected_exercises,
        selected_games_ids: workout.selected_games_ids,
      });

      // Attach the newly created ID to the workout form, so that we know that on the next save
      // attempt we should update it instead.
      form.setValue(
        `workouts.${position}`,
        convertWorkoutToFormValues(response.workout),
        { shouldValidate: true, shouldTouch: true }
      );
      await onWorkoutSaved();

      alert('Workout saved as a copy.');

    } catch (error) {
      alert('Failed to save as copy: ' + JSON.stringify(error));
    }
  }

  const handleResetChanges = () => {
    if (!isEditing(workout)) {
      return;
    }

    const confirmed = confirm('Are you sure you want to reset all unsaved changes made to this workout?');
    if (confirmed) {
      onReset(position, workout.id);
    }
  }

  const isEditing = (maybe_editing_workout?: WorkoutFormValues): maybe_editing_workout is WithRequired<WorkoutFormValues, 'id'> => {
    return !!(maybe_editing_workout || workout)?.id;
  }

  /**
   * Whether or not current user can edit this workout.
   */
  const canModify = () => {
    return on_behalf_of_patient || (workout?.owner_id === patient_id);
  }

  const isValid = (): boolean => {
    return workoutFormSchema.isValidSync(workout ?? {}, { abortEarly: true });
  }

  const canSave = (): boolean => {
    if (!isValid() || !canModify()) {
      return false;
    }

    if (isEditing(workout)) {
      return checkModified(position, workout.id);
    }

    return true;
  }

  const canSaveAsCopy = (): boolean => {
    return isValid() && isEditing();
  }

  const canMoveTo = (direction: 'left' | 'right'): boolean => {
    const index_increment = (direction === "left") ? -1 : 1;

    return !!form.getValues().workouts[position + index_increment];
  }

  const renderStatusChip = () => {
    let status: 'Saved' | 'Prescribed' | 'Modified' | 'New';
    let bg_color: string;
    let text_color: string;
    let hint: JSX.Element;

    if (isEditing(workout)) {
      if (checkModified(position, workout.id)) {
        status = 'Modified';
        bg_color = 'bg-warning';
        text_color = 'text-dark';
        hint = (
          <div>
            <p className="fw-bold m-0 text-start">Unsaved changes made to this workout.</p>
            <br />
            <p className="mt-2 text-start">
              Please note that any changes you save will affect other compound workouts that use this same workout.
            </p>
          </div>
        );
      } else if (!canModify()) {
        status = 'Prescribed';
        bg_color = 'bg-success';
        text_color = 'text-white';
        hint = (
          <p className="m-0 text-start fw-bold">
            Prescribed workout; cannot be edited.
          </p>
        );
      } else {
        status = 'Saved';
        bg_color = 'bg-success';
        text_color = 'text-white';
        hint = (
          <div>
            <div className="m-0 text-start fw-bold">Workout details saved.</div>
            <div className="mt-2">
              Please note that any changes you save will affect other compound workouts that use this same workout.
            </div>
          </div>
        );
      }
    } else {
      status = 'New';
      bg_color = 'bg-dark';
      text_color = 'text-white';
      hint = (
        <p className="m-0 text-start fw-bold">
          Creating a new workout.
        </p>
      );
    }

    return (
      <Chip className={classNames(bg_color, text_color)}>
        <div className="d-flex align-items-center justify-content-center gap-2">
          <OverlayTrigger
            overlay={
              <Tooltip>
                {hint}
              </Tooltip>
            }
            placement="bottom-start"
          >
            <p className="m-0 fw-bold" style={{ cursor: 'help' }}>
              {status}
            </p>
          </OverlayTrigger>

          {status === 'Modified' ? (
            <>
              <div style={{ height: 12, width: 0.6, background: 'black' }} />

              <OverlayTrigger
                overlay={<Tooltip>Reset all unsaved changes.</Tooltip>}
                placement="bottom-start"
              >
                <Button
                  className="m-0 p-0 text-danger"
                  variant="link"
                  onClick={handleResetChanges}
                >
                  <i className="fas fa-history"></i>
                </Button>
              </OverlayTrigger>
            </>
          ) : null}
        </div>
      </Chip>
    )
  }

  return (
    <Card className="border-2">
      <Card.Body className="pb-0">
        <div className="mb-3 d-flex align-items-center justify-content-between gap-2">
          <div className="d-flex align-items-center justify-content-center gap-2">
            {renderStatusChip()}

            <p className="mb-0">Workout {position + 1} of {workout_forms_count}</p>
          </div>

          <div className="d-flex align-items-center justify-content-between">
            <ButtonGroup>
              <OverlayTrigger
                placement='top-start'
                delay={1000}
                overlay={
                  <Tooltip>
                    Move this workout to the left
                  </Tooltip>
                }
              >
                <Button
                  size="sm"
                  variant="outline-dark"
                  className={classNames(!canMoveTo('left') ? 'opacity-25' : undefined)}
                  disabled={!canMoveTo('left')}
                  onClick={() => onMove('left', position)}
                >
                  <i className="fas fa-chevron-left" />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                placement='top-start'
                delay={1000}
                overlay={
                  <Tooltip>
                    Move this workout to the right
                  </Tooltip>
                }
              >
                <Button
                  size="sm"
                  variant="outline-dark"
                  className={classNames(!canMoveTo('right') ? 'opacity-25' : undefined)}
                  disabled={!canMoveTo('right')}
                  onClick={() => onMove('right', position)}
                >
                  <i className="fas fa-chevron-right" />
                </Button>
              </OverlayTrigger>
            </ButtonGroup>
          </div>
        </div>

        {!canModify() ? (
          <Alert variant="light">
            <p className="m-0">
              As this workout has been prescribed for you, it cannot be edited. However, you have the option to 'Save as Copy' and make modifications to the duplicated version if needed.
            </p>

            <hr />

            <div className="d-flex justify-content-end">
              <Button variant="primary" type="button" disabled={!canSaveAsCopy()} onClick={handleSaveAsCopy}>Save as copy</Button>
            </div>
          </Alert>
        ) : null}

        <hr />

        <div className="mb-3">
          <Form.Group className="mb-3">
            <Form.Label>
              Name<RequiredFieldIndicator />:
            </Form.Label>

            <Form.Control
              {...form.register(`workouts.${position}.name`)}
              type="text"
              disabled={!canModify()}
              isInvalid={form.formState.touchedFields.workouts?.[position]?.name && !!form.formState.errors?.workouts?.[position]?.name}
            />
            <Form.Control.Feedback type='invalid'>{form.formState.errors?.workouts?.[position]?.name?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label aria-required>Category:</Form.Label>
            <Form.Select
              {...form.register(`workouts.${position}.category_id`)}
              disabled={!canModify()}
              isInvalid={form.formState.touchedFields.workouts?.[position]?.category_id && !!form.formState.errors?.workouts?.[position]?.category_id}
            >
              {workout_categories.map((workout_category) => (
                <option key={workout_category._id.$oid} value={workout_category._id.$oid}>
                  {workout_category.name}
                </option>
              ))}
            </Form.Select>

            <Form.Control.Feedback type='invalid'>{form.formState.errors?.workouts?.[position]?.category_id?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Difficulty<RequiredFieldIndicator />:</Form.Label>
            <Form.Control
              {...form.register(`workouts.${position}.difficulty`)}
              type="number"
              readOnly={!canModify()}
              isInvalid={form.formState.touchedFields.workouts?.[position]?.difficulty && !!form.formState.errors?.workouts?.[position]?.difficulty}
            />
            <Form.Control.Feedback type='invalid'>{form.formState.errors?.workouts?.[position]?.difficulty?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Number of exercises<RequiredFieldIndicator />:</Form.Label>
            <Form.Control
              {...form.register(`workouts.${position}.number_of_exercises`)}
              type="number"
              readOnly={!canModify()}
              isInvalid={form.formState.touchedFields.workouts?.[position]?.number_of_exercises && !!form.formState.errors?.workouts?.[position]?.number_of_exercises}
            />
            <Form.Control.Feedback type='invalid'>{form.formState.errors?.workouts?.[position]?.number_of_exercises?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Duration<RequiredFieldIndicator />:</Form.Label>
            <Form.Control
              {...form.register(`workouts.${position}.duration`)}
              type="number"
              readOnly={!canModify()}
              isInvalid={form.formState.touchedFields.workouts?.[position]?.duration && !!form.formState.errors?.workouts?.[position]?.duration}
            />
            <Form.Control.Feedback type='invalid'>{form.formState.errors?.workouts?.[position]?.duration?.message}</Form.Control.Feedback>
          </Form.Group>
        </div>

        <hr />

        <GamesSelectionFormGroup
          forWorkoutFormPosition={position}
          canModify={canModify()}
          games={games}
        />

        <hr />

        <ExercisesSelectionFormGroup
          for_workout_form_position={position}
          canModify={canModify()}
          exercises={exercises}
          exercise_types={exercise_types}
          exercise_categories={exercise_categories}
          exercise_dependencies={exercise_dependencies}
        />
      </Card.Body>

      <Card.Footer className="d-flex py-3 gap-2 justify-content-between">
        <Button variant="danger" type="button" onClick={() => onRemove(position)}>Remove</Button>

        <div className="d-flex gap-2">
          {workout?.id ? (
            <Button variant={!canModify() ? 'primary' : 'outline-primary'} type="button" disabled={!canSaveAsCopy()} onClick={handleSaveAsCopy}>Save as copy</Button>
          ) : null}

          {canModify() ? (
            <Button variant="primary" type="button" disabled={!canSave()} onClick={handleSave}>
              {workout?.id ? 'Save changes' : 'Save'}
            </Button>
          ) : null}
        </div>
      </Card.Footer>
    </Card>
  )
}
