import React, { ChangeEventHandler, useState } from "react"
import { Button, Card, Form } from "react-bootstrap"
import { Game, Exercise, ExerciseType, WorkoutCategory, ExerciseCategory, ExerciseDependency, Workout } from "../../../libs/types";
import { WorkoutForm } from "./WorkoutForm";
import { useFieldArray, useFormContext } from "react-hook-form";
import { CompoundWorkoutFormValues, convertWorkoutToFormValues, workoutFormSchema } from "./schema";
import useAsyncEffect from "use-async-effect";
import { apiGetAllWorkouts } from "../../../api/resources/patients/workouts/apiGetAllWorkouts";
import { groupBy, sort, sortBy } from "array-fns";
import classNames from "classnames";

type WorkoutFormGroup = {
  on_behalf_of_patient: boolean,
  patient_id: string,
  games: Game[],
  exercises: Exercise[],
  exercise_types: ExerciseType[],
  workout_categories: WorkoutCategory[],
  exercise_categories: ExerciseCategory[],
  exercise_dependencies: ExerciseDependency[]
};

export const WorkoutFormGroup = ({
  on_behalf_of_patient,
  patient_id,
  games,
  exercises,
  workout_categories,
  exercise_types,
  exercise_categories,
  exercise_dependencies,
}: WorkoutFormGroup) => {
  const form = useFormContext<CompoundWorkoutFormValues>();
  const { fields: workout_fields, append, remove, swap } = useFieldArray({
    control: form.control,
    name: 'workouts'
  });

  const [selected_existing_workout_id, setSelectedExistingWorkoutId] = useState<string>('none');

  const [patient_workouts, setPatientWorkouts] = useState<Workout[]>([]);
  const [other_workouts, setOtherWorkouts] = useState<Workout[]>([]);

  useAsyncEffect(async () => {
    await fetchWorkouts()
      .catch(console.error);
  }, []);

  const fetchWorkouts = async () => {
    try {
      const response = await apiGetAllWorkouts();

      const patient_workout_ids = response.workout_patients
        .filter((wp) => wp.patient_id.$oid === patient_id)
        .map((wp) => wp.workout_id.$oid);

      const grouped_workouts = groupBy(response.workouts, (workout) => patient_workout_ids.includes(workout._id.$oid));
      const patient_workouts = grouped_workouts.filter((group) => group?.[0] === true)?.[0]?.[1] || [];
      const other_workouts = grouped_workouts.filter((group) => group?.[0] === false)?.[0]?.[1] || [];

      setPatientWorkouts(patient_workouts);
      setOtherWorkouts(other_workouts);
    } catch (error) {
      console.error('Failed to fetch existing workouts to populate the select box.', error);
    }
  }

  const handleAddNewWorkout = () => {
    append({
      name: '',
      category_id: '',
      difficulty: 0,
      duration: 0,
      number_of_exercises: 0,
      selected_exercises: [],
      selected_games_ids: [],
      owner_id: patient_id
    });
  }

  const getExistingWorkoutById = (workout_id: string): Workout | undefined => {
    return [...patient_workouts, ...other_workouts].find((workout) => workout._id.$oid === workout_id);
  }

  const removeWorkout = (field_index: number) => {
    remove(field_index);
  }

  const resetWorkoutChanges = (field_index: number, workout_id: string) => {
    const workout = getExistingWorkoutById(workout_id);
    if (!workout) {
      return;
    }

    const default_values = convertWorkoutToFormValues(workout);
    form.setValue(`workouts.${field_index}`, default_values, { shouldValidate: true });
  }

  const handleExistingWorkoutSelectionChange: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const new_workout_id = event.target.value;
    setSelectedExistingWorkoutId(new_workout_id);

    if (!new_workout_id || new_workout_id === 'none') {
      return;
    }

    const workout = getExistingWorkoutById(new_workout_id);
    if (!workout) {
      return;
    }

    append(convertWorkoutToFormValues(workout));

    // Reset
    setSelectedExistingWorkoutId('none');
  }

  const checkNameModified = (field_index: number, workout_id: string): boolean => {
    const current_values = workoutFormSchema.cast(form.getValues()?.workouts?.[field_index], { assert: false });
    if (!current_values) {
      return false;
    }

    const existing_workout = getExistingWorkoutById(workout_id);
    if (!existing_workout) {
      return false;
    }

    const default_values = convertWorkoutToFormValues(existing_workout);

    return current_values.name?.trim() !== default_values.name?.trim();
  }

  const checkModified = (field_index: number, workout_id: string): boolean => {
    const current_values = workoutFormSchema.cast(form.getValues()?.workouts?.[field_index], { assert: false });
    if (!current_values) {
      return false;
    }

    const existing_workout = getExistingWorkoutById(workout_id);
    if (!existing_workout) {
      return false;
    }

    const default_values = convertWorkoutToFormValues(existing_workout);

    if (current_values.name?.trim() !== default_values.name?.trim()) {
      return true;
    }

    if (current_values.duration !== default_values?.duration) {
      return true;
    }

    if (current_values.difficulty !== default_values?.difficulty) {
      return true;
    }

    if (current_values.number_of_exercises !== default_values?.number_of_exercises) {
      return true;
    }

    if (current_values.category_id !== default_values?.category_id) {
      return true;
    }

    // Check if game selection has changed
    const sorted_default_selected_games_ids = sort(default_values.selected_games_ids ?? []);
    const sorted_current_selected_games_ids = sort(current_values.selected_games_ids);
    if (sorted_default_selected_games_ids.length !== sorted_current_selected_games_ids.length) {
      return true;
    } else {
      const has_changed_selected_games = !sorted_current_selected_games_ids.every((game_id) => sorted_default_selected_games_ids.includes(game_id));
      if (has_changed_selected_games) {
        return true;
      }
    }

    const sorted_default_selected_exercises = sortBy(default_values.selected_exercises ?? [], (s) => s?.id);
    const sorted_current_selected_exercises = sortBy(current_values.selected_exercises, (s) => s.id);
    if (sorted_default_selected_exercises.length !== sorted_current_selected_exercises.length) {
      return true;
    } else {
      const has_changed_selected_exercises = !sorted_current_selected_exercises.every((exercise, index) => {
        const has_changed_exercise = exercise.id === sorted_default_selected_exercises[index]?.id;
        const has_changed_emphasis = exercise.emphasis === sorted_default_selected_exercises[index]?.emphasis;

        return has_changed_exercise && has_changed_emphasis;
      });
      if (has_changed_selected_exercises) {
        return true;
      }
    }

    return false;
  }

  const handleWorkoutSaved = async () => {
    await fetchWorkouts()
      .catch(console.error);
  }

  const handleMoveTo = (direction: 'left' | 'right', field_index: number) => {
    const index_increment = (direction === 'left') ? -1 : 1;
    const to_field_index = field_index + index_increment;

    swap(field_index, to_field_index);
  }

  return (
    <div className="d-flex flex-nowrap overflow-scroll gap-3">
      {workout_fields.map((field, field_index) => (
        <div key={field.id + field_index} style={{ width: 480, minWidth: 480 }}>
          <WorkoutForm
            on_behalf_of_patient={on_behalf_of_patient}
            patient_id={patient_id}
            position={field_index}
            workout_forms_count={workout_fields.length}
            games={games}
            exercises={exercises}
            exercise_types={exercise_types}
            workout_categories={workout_categories}
            exercise_categories={exercise_categories}
            exercise_dependencies={exercise_dependencies}
            onWorkoutSaved={handleWorkoutSaved}
            onReset={resetWorkoutChanges}
            onRemove={removeWorkout}
            checkNameModified={checkNameModified}
            checkModified={checkModified}
            onMove={handleMoveTo}
          />
        </div>
      ))}

      <div className={classNames(!workout_fields.length ? 'w-100' : undefined)} style={{ minWidth: workout_fields.length ? 320 : undefined, marginRight: workout_fields.length ? 360 : undefined }}>
        <Card className="border-3 bg-light">
          <Card.Body className="px-4 py-5 d-flex flex-column align-items-center">
            {!workout_fields.length ? (
              <h4 className="mt-0 mb-5">Add workouts to this compound workout</h4>
            ) : null}

            <Button className="mb-2" onClick={handleAddNewWorkout}>
              <i className="fas fa-plus me-2" />

              New workout
            </Button>

            {(patient_workouts.length || other_workouts.length) ? (
              <>
                <p className="mb-2">or:</p>

                <Form.Select
                  className="border-dark"
                  style={{ width: !workout_fields.length ? 270 : undefined }}
                  onChange={handleExistingWorkoutSelectionChange}
                  value={selected_existing_workout_id}
                >
                  <option
                    key="none"
                    value="none"
                    disabled
                  >
                    Select from existing workouts...
                  </option>

                  {patient_workouts.length ? (
                    <optgroup label={on_behalf_of_patient ? 'Assigned to this patient:' : 'Assigned to me:'}>
                      {patient_workouts.map((workout) => (
                        <option key={workout._id.$oid} value={workout._id.$oid}>
                          {workout.name}
                        </option>
                      ))}
                    </optgroup>
                  ) : null}

                  {other_workouts.length ? (
                    <optgroup label="Assigned to other patients:">
                      {other_workouts.map((workout) => (
                        <option key={workout._id.$oid} value={workout._id.$oid}>
                          {workout.name}
                        </option>
                      ))}
                    </optgroup>
                  ) : null}
                </Form.Select>
              </>
            ) : null}
          </Card.Body>
        </Card>
      </div>
    </div>
  )
}
