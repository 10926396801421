import { chain } from 'array-fns';
import { CompoundWorkout, Workout } from '../../../libs/types';
import * as yup from 'yup';

export const workoutFormSchema = yup.object().shape({
  id: yup.string().optional(),
  name: yup.string().label('Name').trim().required('Please enter a name.'),
  category_id: yup.string().label('Category').required('Please select a category.'),
  difficulty: yup.number().label('Difficulty')
    .typeError('Must be a number.')
    .min(1)
    .required('Please enter the difficulty'),
  number_of_exercises: yup.number().label('Number of exercises')
    .typeError('Must be a number.')
    .min(1)
    .required('Please enter the number of exercises.'),
  duration: yup.number().label('Duration')
    .typeError('Must be a number.')
    .min(1)
    .required('Please enter the duration.'),
  selected_exercises: yup.array().of(
      yup.object().shape({
        id: yup.string().required(),
        emphasis: yup.number()
          .label('Emphasis')
          .typeError('Must be a number.')
          .min(1)
          .max(10)
          .required()
      })
    ).label('Exercises')
    .min(1, 'Please select at least one exercise.')
    .required(),
  selected_games_ids: yup.array().of(yup.string().required())
    .min(1, 'Please select at least one compatible game.')
    .required()
    .label('Games'),
  owner_id: yup.string().required()
}).required();

export type WorkoutFormValues = yup.InferType<typeof workoutFormSchema>;

export const compoundWorkoutFormSchema = yup.object({
  name: yup.string().trim().required('Please enter a name.').label('Name'),
  workout_category_id: yup.string().required('Please select a category.').label('Category'),
  workouts: yup.array().of(workoutFormSchema).min(1).required().label('Workouts')
}).required();

export type CompoundWorkoutFormValues = yup.InferType<typeof compoundWorkoutFormSchema>;

/* --------------------------- Conversion helpers --------------------------- */

export const convertWorkoutToFormValues = (workout: Workout): WorkoutFormValues => {
  return {
    id: workout._id.$oid,
    name: workout.name,
    category_id: workout.workout_category_id.$oid,
    difficulty: workout.max_difficulty,
    duration: workout.duration,
    number_of_exercises: workout.num_exercises,
    selected_exercises: chain(Object.keys(workout.exercises))
      .filter((exercise_id) => workout.exercises[exercise_id].selected === '1')
      .map((exercise_id) => ({ id: exercise_id, emphasis: parseInt(workout.exercises[exercise_id].emphasis) }))
      .toArray(),
    selected_games_ids: workout.selected_games_ids,
    owner_id: workout.owner_id.$oid,
  }
}

export const convertCompoundWorkoutToFormValues = (compoundWorkout: CompoundWorkout, workouts: Workout[]): CompoundWorkoutFormValues => {
  return {
    name: compoundWorkout.name,
    workout_category_id: compoundWorkout.workout_category_id.$oid,
    workouts: workouts.map((workout) => convertWorkoutToFormValues(workout))
  }
}
