import classNames from "classnames"
import React, { HTMLProps } from "react"

export const Chip = ({ children, className, ...restProps }: HTMLProps<HTMLDivElement>) => {
  return (
    <div className={classNames("d-inline-block px-2 py-1 rounded-3", className)} {...restProps}>
      {children}
    </div>
  )
}
