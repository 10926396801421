import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['gameSelect']
  
  connect() {
    this.gameSelectTarget.addEventListener('change', () => {
      this.#reloadPage()
    });
  }
  
  #reloadPage() {
    // Get the current URL
    const currentUrl = new URL(window.location.href);

    const newGameId = this.gameSelectTarget.value
    currentUrl.searchParams.set('game_id', newGameId);

    // Reload the page with the modified URL
    window.location.href = currentUrl.href;
  }
}
