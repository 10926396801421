import React, { Fragment } from "react";
import { Form, Table } from "react-bootstrap";
import { Exercise, ExerciseCategory, ExerciseDependency, ExerciseType } from "../../../libs/types";
import { groupBy } from "array-fns";
import { useFormContext, useWatch } from "react-hook-form";
import { CompoundWorkoutFormValues } from "./schema";
import { produce } from "immer";
import { RequiredFieldIndicator } from "../../../libs/shared_components/RequiredFieldIndicator";
import classNames from "classnames";

type ExercisesSelectionTableProps = {
  for_workout_form_position: number,
  canModify: boolean,
  exercises: Exercise[],
  exercise_types: ExerciseType[],
  exercise_categories: ExerciseCategory[],
  exercise_dependencies: ExerciseDependency[],
}

export const ExercisesSelectionFormGroup = ({
  for_workout_form_position,
  canModify,
  exercises,
  exercise_types,
  exercise_categories,
  exercise_dependencies
}: ExercisesSelectionTableProps) => {
  const form = useFormContext<CompoundWorkoutFormValues>();
  const selected_exercises = useWatch({
    control: form.control,
    name: `workouts.${for_workout_form_position}.selected_exercises`,
    defaultValue: [],
  });

  /**
   * Returns whether or not the {@link exercise_id} is of an "Exercise Type" exercise.
   */
  const isExerciseExerciseType = (exercise_id: string) => {
    return exercise_types.some(
      (type) => type._id.$oid === exercise_id
    );
  }

  const isExerciseSelectable = (exercise_id: string) => {
    if (!canModify) {
      return false;
    }

    const is_exercise_type = isExerciseExerciseType(exercise_id);
    if (is_exercise_type) {
      // All "Exercise Type" exercises should always be selectable / enabled.
      return true;
    }

    const is_dependent = exercise_dependencies.some(
      (dependency) => dependency.exercise_ids.some(id =>
        id.$oid === exercise_id
      )
    );

    if (!is_dependent) {
      // This exercise is selectable only if at least one of the "Exercise Type" exercises is selected:
      // const selected_exercises = form.getValues().workouts[for_workout_form_position].selected_exercises
      const has_selected_an_exercise_type = selected_exercises.some((selected_exercise) => isExerciseExerciseType(selected_exercise.id));
      return has_selected_an_exercise_type;

    } else {
      // This exercise is selectable only if its parent dependency is selected:
      // const current_selected_exercises = form.getValues().workouts[for_workout_form_position].selected_exercises;
      const current_selected_exercise_types = selected_exercises.filter((selected_exercise) => isExerciseExerciseType(selected_exercise.id));

      const is_dependent_on_a_selected_exercise_type = current_selected_exercise_types.some((selected_exercise_type) => {
        return exercise_dependencies.some((dependency) => {
          return (dependency.exercise_type_id.$oid === selected_exercise_type.id) && (dependency.exercise_ids.some((id) => id.$oid === exercise_id));
        });
      });

      return is_dependent_on_a_selected_exercise_type;
    }
  }

  const onExerciseSelection = (selected: boolean, exercise_id: string) => {
    const current_selected_exercises = form.getValues().workouts[for_workout_form_position].selected_exercises;
    const updated_selected_exercises = produce(current_selected_exercises, (draft) => {
      const existing_selected_exercise_index = draft.findIndex((selected_exercise) => selected_exercise.id === exercise_id);
      if (selected) {
        // Select this exercise, add to the list
        if (existing_selected_exercise_index === -1) {
          const default_emphasis_value = 5;

          draft.push({
            id: exercise_id,
            emphasis: default_emphasis_value
          });
        }

      } else {
        // Unselect this exercise, remove from the list
        if (existing_selected_exercise_index !== -1) {
          draft.splice(existing_selected_exercise_index, 1);
        }
      }
    });

    form.setValue(`workouts.${for_workout_form_position}.selected_exercises`, updated_selected_exercises);
  }

  const handleExerciseSelectionCheckbox = (event: React.ChangeEvent<HTMLInputElement>, exercise_id: string) => {
    const checked = event.currentTarget.checked;
    onExerciseSelection(checked, exercise_id);

    // If unselecting an "Exercise Type", then unselect all dependent exercises, unless, that exercise is also dependent
    // on another "Exercise Type" that is currently selected.
    const is_exercise_type_exercise = isExerciseExerciseType(exercise_id);
    const current_selected_exercises = selected_exercises;

    if (!checked && is_exercise_type_exercise) {
      // Unselect all non-selectable exercises
      for (const selected_exercise of current_selected_exercises) {
        if (!isExerciseSelectable(selected_exercise.id)) {
          onExerciseSelection(false, selected_exercise.id);
        }
      }
    }
  }

  const onSelectedExerciseEmphasisChange = (newEmphasis: number, exercise: Exercise) => {
    const exercise_index = selected_exercises.findIndex((selected_exercise) => selected_exercise.id === exercise._id.$oid)

    form.setValue(`workouts.${for_workout_form_position}.selected_exercises.${exercise_index}.emphasis`, newEmphasis, { shouldValidate: true, shouldTouch: true });
  }

  const shouldShowInvalidMessage = (): boolean => {
    if (!canModify) {
      return false;
    }

    const touched = !!form.formState.touchedFields.workouts?.[for_workout_form_position]?.selected_exercises;
    return touched && !selected_exercises.length;
  }

  const shouldShowSelectedExerciseEmphasisInvalidMessage = (selected_exercise_index: number) => {
    if (!canModify) {
      return false;
    }

    const touched = !!form.formState.touchedFields.workouts?.[for_workout_form_position]?.selected_exercises?.[selected_exercise_index];
    const hasValidationError = !!form.formState.errors.workouts?.[for_workout_form_position]?.selected_exercises?.[selected_exercise_index];

    return touched && hasValidationError;
  }

  const renderRows = () => {
    const exercises_grouped_by_category = groupBy(exercises, (exercise) => exercise.exercise_category_id.$oid);

    const rows = exercises_grouped_by_category.map(([category_id, category_exercises]) => {
      const exercise_category = exercise_categories.find((category) => category._id.$oid === category_id);

      return (
        <Fragment key={category_id}>
          <tr key={`divider-${category_id}`} className="table-light">
            <td colSpan={3}>
              {`${exercise_category?.name}:` ?? 'N/a'}
            </td>
          </tr>

          {category_exercises.map((exercise) => {
            const is_exercise_selectable = isExerciseSelectable(exercise._id.$oid);

            const selected_exercise_index = selected_exercises.findIndex(
              (selected_exercise) => selected_exercise.id === exercise._id.$oid
            );
            const selected_exercise = selected_exercises[selected_exercise_index];

            const show_emphasis_invalid_message = shouldShowSelectedExerciseEmphasisInvalidMessage(selected_exercise_index);

            return (
              <tr key={exercise._id.$oid} className={!is_exercise_selectable ? 'text-muted' : undefined}>
                <td>
                  <Form.Check
                    type="checkbox"
                    value={exercise._id.$oid}
                    disabled={!canModify || !is_exercise_selectable}
                    checked={!!selected_exercise}
                    onChange={(event) => handleExerciseSelectionCheckbox(event, exercise._id.$oid)}
                  />
                </td>
                <td>
                  {exercise.name}
                </td>
                <td>
                  <Form.Group>
                    <Form.Control
                      type="number"
                      disabled={!canModify || !selected_exercise}
                      value={selected_exercise?.emphasis ?? ''}
                      onChange={(event) => onSelectedExerciseEmphasisChange(parseInt(event.currentTarget.value ?? 0), exercise)}
                      isInvalid={show_emphasis_invalid_message}
                    />
                    <Form.Control.Feedback type='invalid'>
                      {form.formState.errors.workouts?.[for_workout_form_position]?.selected_exercises?.[selected_exercise_index]?.emphasis?.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </td>
              </tr>
            );
          })}
        </Fragment>
      )
    });

    return rows;
  }

  return (
    <Form.Group>
      <Form.Label className={classNames(shouldShowInvalidMessage() ? 'is-invalid mb-0' : null)}>
        Exercises<RequiredFieldIndicator />:
      </Form.Label>
      <Form.Control.Feedback className="mb-2" type="invalid">
        Please select exercises that form at least one compatible game.
      </Form.Control.Feedback>

      <Table bordered>
        <thead>
          <tr>
            <th style={{ width: 1, borderRight: 'none' }}></th>
            <th style={{ borderLeft: 'none' }}></th>
            <th className="text-center" style={{ width: 140 }}>Emphasis</th>
          </tr>
        </thead>
        <tbody>
          {renderRows()}
        </tbody>
      </Table>
    </Form.Group>
  )
}
