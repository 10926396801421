import { apiClient } from "../../apiClient"

export type ApiGetGameCompatibilityResponse = {
  result: number;
}

export type ApiGetGameCompatibilityRequest = {
  game_id: string;
  selected_exercises_ids: string[];
};

/**
 * Retrieve the compatibility %'s for selected game and exercises
 */
export const apiGetGameCompatibility = async (
  {
    game_id,
    ...request
  }: ApiGetGameCompatibilityRequest
): Promise<ApiGetGameCompatibilityResponse> => {
  const response = await apiClient.post(
    `/games/${game_id}/compatibility/`,
    request
  );

  return response.data;
}
