import { Workout } from "../../../../libs/types";
import { apiClient } from "../../../apiClient"

export type ApiUpdateWorkoutRequest = {
  patient_id: string,
  id: string,
  name: string,
  workout_category_id: string,
  max_difficulty: number,
  number_of_exercises: number,
  duration: number,
  selected_exercises: { id: string, emphasis: number }[],
  selected_games_ids: string[],
};

export type ApiUpdateWorkoutResponse = {
  workout: Workout;
}

export const apiUpdateWorkout = async (request: ApiUpdateWorkoutRequest): Promise<ApiUpdateWorkoutResponse> => {
  const response = await apiClient.put(
    `api/internal/workouts/${request.id}`,
    { workout: request }
  );
  return response.data;
}
