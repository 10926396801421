import React, { useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { playlistFormSchema, PlaylistFormValues } from "./schema";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Card, DropdownDivider, Form } from "react-bootstrap";
import { Playlist, PlaylistWorkout, PlaylistWorkoutOrCompoundWorkout, WorkoutCategory } from "../../../libs/types";
import { PlaylistTable } from "./PlaylistTable";
import { apiCreatePlaylist } from "../../../api/resources/patients/playlists/apiCreatePlaylist";
import { apiUpdatePlaylist } from "../../../api/resources/patients/playlists/apiUpdatePlaylist";
import { RequiredFieldIndicator } from "../../../libs/shared_components/RequiredFieldIndicator";
import { apiActivatePlaylist } from "../../../api/resources/patients/playlists/apiActivatePlaylist";

type PlaylistFormProps = {
  /**
   * Indicates whether you're filling this form on behalf of the patient,
   * rather than being the actual patient. For example, this is `true` for
   * an admin creating a playlist for a patient, and `false` when the actual patient
   * is creating their own playlist
   */
  on_behalf_of_patient: boolean,
  patient_id: string,
  editing_details: {
    playlist: Playlist,
    playlist_workouts: PlaylistWorkout[]
  } | undefined,
  workouts_and_compound_workouts: PlaylistWorkoutOrCompoundWorkout[]
  workout_categories: WorkoutCategory[]
}

const PlaylistForm = ({
  on_behalf_of_patient,
  patient_id,
  editing_details,
  workout_categories,
  workouts_and_compound_workouts,
}: PlaylistFormProps) => {
  // States
  const [active, setActive] = useState<boolean>(editing_details?.playlist.active ?? false);

  // Hooks
  const form = useForm<PlaylistFormValues>({
    resolver: yupResolver(playlistFormSchema),
    defaultValues: editing_details ? {
      name: editing_details.playlist.name,
      category_id: editing_details.playlist.workout_category_id.$oid,
      loop: editing_details.playlist.loop,
      shuffle: editing_details.playlist.shuffle,
      selected_workouts: editing_details.playlist_workouts.map((playlist_workout) => ({
        id: playlist_workout.base_workout_id.$oid,
        type: playlist_workout.base_workout_type
      }))
    } : {
      name: '',
      category_id: '',
      loop: false,
      shuffle: false,
      selected_workouts: []
    },
    mode: 'all',
    reValidateMode: 'onChange'
  });

  const onSaveAsCopy: SubmitHandler<PlaylistFormValues> = async (data) => {
    submit(data, true);
  }

  const onSaveOrCreate: SubmitHandler<PlaylistFormValues> = async (data) => {
    submit(data);
  }

  const submit = async (data: PlaylistFormValues, duplicate = false) => {
    try {
      if (duplicate || !editing_details) {
        // We're either duplicating this playlist, that is, saving it as a new copy
        // or creating a fresh new playlis.
        const response = await apiCreatePlaylist({
          patient_id,
          name: data.name,
          category_id: data.category_id,
          loop: data.loop,
          shuffle: data.shuffle,
          workouts: data.selected_workouts,
        });

        let next_page_path: string;
        if (duplicate) {
          // Navigate to the editing page of the duplicated compound workout:
          if (on_behalf_of_patient) {
            // On behalf of the patient (e.g. an admin creating for a patient):
            next_page_path = `/patients/${patient_id}/playlists/${response.playlist._id.$oid}/edit`;
          } else {
            // As the actual patient:
            next_page_path = `/me/playlists/${response.playlist._id.$oid}/edit`;
          }
        } else {
          if (on_behalf_of_patient) {
            // On behalf of the patient (e.g. an admin creating for a patient):
            next_page_path = `/patients/${patient_id}/playlists/`;
          } else {
            // As the actual patient:
            next_page_path = `/me/playlists/`;
          }
        }

        window.location.pathname = next_page_path;

      } else if (editing_details) {
        // We're editing an existing playlist, so save the changes.
        await apiUpdatePlaylist({
          patient_id,
          playlist_id: editing_details.playlist._id.$oid,
          name: data.name,
          category_id: data.category_id,
          loop: data.loop,
          shuffle: data.shuffle,
          workouts: data.selected_workouts,
        });

        alert('Playlist updated!');
      }

    } catch (error) {
      alert('Failed to create: ' + JSON.stringify(error));
    }
  };

  const onActivate = async () => {
    if (!editing_details) {
      return;
    }

    const oldState = active;
    const newState = !oldState;


    try {
      setActive(newState);
      await apiActivatePlaylist({
        playlist_id: editing_details.playlist._id.$oid
      });

      alert('Playlist activated');

    } catch (error) {
      alert('Failed to activate playlist' + JSON.stringify(error));

      // Revert state:
      setActive(oldState);
    }
  }

  return (
    <FormProvider {...form}>
      <form>
        <Card>
          <Card.Header>
            Details
          </Card.Header>
          <Card.Body>
            <Form.Group className="mb-3">
              <Form.Label>Name<RequiredFieldIndicator />:</Form.Label>
              <Form.Control
                type="text"
                {...form.register('name')}
                isInvalid={form.formState.touchedFields.name && !!form.formState.errors.name}
              />
              <Form.Control.Feedback type='invalid'>{form.formState.errors.name?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-4">
              <Form.Label>Category<RequiredFieldIndicator />:</Form.Label>
              <Form.Select
                {...form.register('category_id')}
                isInvalid={form.formState.touchedFields.category_id && !!form.formState.errors.category_id}
              >
                {workout_categories.map((workout_category) => (
                  <option key={workout_category._id.$oid} value={workout_category._id.$oid}>
                    {workout_category.name}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type='invalid'>{form.formState.errors.category_id?.message}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Check
                {...form.register('loop')}
                type="checkbox"
                label="Loop"
              />
            </Form.Group>

            <Form.Group className="mb-2">
              <Form.Check
                {...form.register('shuffle')}
                type="checkbox"
                label="Shuffle"
              />
            </Form.Group>

            {editing_details ? (
              <>
                <DropdownDivider />

                <Form.Group className="mb-2">
                  <Form.Check
                    checked={active}
                    type="radio"
                    label="Active"
                    onChange={onActivate}
                  />
                </Form.Group>
              </>
            ) : null}
          </Card.Body>
        </Card>

        <PlaylistTable
          workouts_and_compound_workouts={workouts_and_compound_workouts}
        />

        <Card>
          <Card.Body className="text-end">
            {editing_details ? (
              <Button onClick={form.handleSubmit(onSaveAsCopy)} className="me-2" variant="secondary" disabled={!form.formState.isValid}>
                Save as copy
              </Button>
            ) : null}

            <Button onClick={form.handleSubmit(onSaveOrCreate)} variant="primary" type="submit" disabled={!form.formState.isValid}>
              {editing_details ? 'Save' : 'Create'}
            </Button>
          </Card.Body>
        </Card>
      </form>
    </FormProvider>
  )
}

export default PlaylistForm;
