import React, { ChangeEventHandler, useState } from "react"
import { Button, ButtonGroup, Card, Col, Form, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap"
import { useFormContext, useWatch } from "react-hook-form"
import { PlaylistFormValues } from "./schema"
import { PlaylistWorkoutOrCompoundWorkout, WorkoutCategory } from "../../../libs/types"
import { produce } from "immer"
import moment from "moment"
import { formatMinutes } from "../../../libs/formatMinutes"
import classNames from "classnames"

type PlaylistTableProps = {
  workouts_and_compound_workouts: PlaylistWorkoutOrCompoundWorkout[]
}

export const PlaylistTable = ({
  workouts_and_compound_workouts,
}: PlaylistTableProps) => {
  const form = useFormContext<PlaylistFormValues>();
  const selected_workouts = useWatch({
    control: form.control,
    name: 'selected_workouts',
  }) || [];

  const [selected_workout_id, setSelectedWorkoutId] = useState<string>('none');

  const handleWorkoutSelection: ChangeEventHandler<HTMLSelectElement> = (event) => {
    const new_workout_id = event.target.value;
    setSelectedWorkoutId(new_workout_id);

    if (!new_workout_id || new_workout_id === 'none') {
      return;
    }

    const workout = workouts_and_compound_workouts.find((workout) => workout._id.$oid === new_workout_id);
    if (!workout) {
      return;
    }

    const updated_selected_workouts_ids = produce(selected_workouts, (draft) => {
      draft.push({
        id: workout._id.$oid,
        type: workout.type,
      });
    });

    form.setValue('selected_workouts', updated_selected_workouts_ids, { shouldValidate: true, shouldTouch: true });

    // Reset
    setSelectedWorkoutId('none');
  }

  const humanizeWorkoutType = (workoutType: string) => {
    if (workoutType === 'CompoundWorkout') {
      return 'Compound Workout';
    }

    return workoutType;
  }

  const canMoveTo = (direction: 'up' | 'down', workout_index: number): boolean => {
    const index_increment = (direction === "up") ? -1 : 1;
    const to_workout_index = workout_index + index_increment;

    return !!form.getValues().selected_workouts[to_workout_index];
  }

  const handleMoveTo = (direction: 'up' | 'down', workout_index: number) => {
    const index_increment = (direction === "up") ? -1 : 1;
    const to_workout_index = workout_index + index_increment;

    const updated_selected_workouts_ids = produce(form.getValues().selected_workouts, draft => {
      const thisWorkout = draft[workout_index];

      draft[workout_index] = draft[to_workout_index];
      draft[to_workout_index] = thisWorkout;
    });

    form.setValue('selected_workouts', updated_selected_workouts_ids, { shouldValidate: true, shouldTouch: true });
  }

  const handleRemoveFromPlaylist = (selected_workout_index: number) => {
    const updated_selected_workouts = produce(selected_workouts, (draft) => {
      draft.splice(selected_workout_index, 1);
    });

    form.setValue('selected_workouts', updated_selected_workouts, { shouldValidate: true, shouldTouch: true });
  }

  const renderRows = () => {
    const rows = selected_workouts.map((selected_workout, index) => {
      const workout_or_compound_workout = workouts_and_compound_workouts.find((workout) => workout._id.$oid === selected_workout.id);

      return (
        <tr key={index + selected_workout.id}>
          <td>
            <div className="d-flex align-items-center justify-content-between">
              <ButtonGroup>
                <OverlayTrigger
                  placement='top-start'
                  delay={1000}
                  overlay={
                    <Tooltip>
                      Move this workout up in the order.
                    </Tooltip>
                  }
                >
                  <Button
                    size="sm"
                    variant="outline-dark"
                    className={classNames(!canMoveTo('up', index) ? 'opacity-25' : undefined)}
                    disabled={!canMoveTo('up', index)}
                    onClick={() => handleMoveTo('up', index)}
                  >
                    <i className="fas fa-chevron-up" />
                  </Button>
                </OverlayTrigger>

                <OverlayTrigger
                  placement='top-start'
                  delay={1000}
                  overlay={
                    <Tooltip>
                      Move this workout down in the order.
                    </Tooltip>
                  }
                >
                  <Button
                    size="sm"
                    variant="outline-dark"
                    className={classNames(!canMoveTo('down', index) ? 'opacity-25' : undefined)}
                    disabled={!canMoveTo('down', index)}
                    onClick={() => handleMoveTo('down', index)}
                  >
                    <i className="fas fa-chevron-down" />
                  </Button>
                </OverlayTrigger>
              </ButtonGroup>
            </div>
          </td>

          <td>{workout_or_compound_workout?.name ?? 'N/a'}</td>
          <td>{workout_or_compound_workout?.type ? humanizeWorkoutType(workout_or_compound_workout.type) : 'N/a'}</td>
          <td>{workout_or_compound_workout?.category_name ?? 'N/a'}</td>
          <td>{workout_or_compound_workout?.created_at ? moment(workout_or_compound_workout.created_at).format('DD/MM/YYYY') : 'N/a'}</td>
          <td>{workout_or_compound_workout?.duration !== undefined ? formatMinutes(workout_or_compound_workout.duration) : 'N/a'}</td>
          <td>
            <Button type="button" onClick={() => handleRemoveFromPlaylist(index)}>Remove</Button>
          </td>
        </tr>
      );
    });

    return rows;
  }

  const renderNormalWorkoutsSelectGroupWithOptions = () => {
    const workouts = workouts_and_compound_workouts.filter((workout) => workout.type === 'Workout');
    if (workouts.length === 0) {
      return null;
    }

    return (
      <optgroup label="Workouts:">
        {workouts.map((workout) => (
          <option key={workout._id.$oid} value={workout._id.$oid}>
            {workout.name}
          </option>
        ))}
      </optgroup>
    );
  }

  const renderCompoundWorkoutsSelectGroupWithOptions = () => {
    const compound_workouts = workouts_and_compound_workouts.filter((compound_workout) => compound_workout.type === 'CompoundWorkout');
    if (compound_workouts.length === 0) {
      return null;
    }

    return (
      <optgroup label="Compound workouts:">
        {compound_workouts.map((compound_workout) => (
          <option key={compound_workout._id.$oid} value={compound_workout._id.$oid}>
            {compound_workout.name}
          </option>
        ))}
      </optgroup>
    );
  }

  return (
    <>
      {
        selected_workouts.length ? (
          <Card>
            <Card.Header>Workouts</Card.Header>

            <Card.Body>
              <Table bordered>
                <thead>
                  <tr>
                    <th style={{ width: 1 }} />
                    <th>Name</th>
                    <th style={{ width: 150 }}>Type</th>
                    <th style={{ width: 200 }}>Category</th>
                    <th style={{ width: 1 }}>Date added</th>
                    <th style={{ width: 1 }}>Duration</th>
                    <th style={{ width: 1 }}></th>
                  </tr>
                </thead>
                <tbody>
                  {renderRows()}

                  <tr className="bg-light">
                    <td colSpan={7}>
                      <Form.Select
                        className="border-dark"
                        style={{ maxWidth: 560 }}
                        value={selected_workout_id}
                        onChange={handleWorkoutSelection}
                      >
                        <option
                          key="none"
                          value="none"
                          disabled
                        >
                          Add from existing workouts and compound wokouts...
                        </option>

                        {renderNormalWorkoutsSelectGroupWithOptions()}
                        {renderCompoundWorkoutsSelectGroupWithOptions()}
                      </Form.Select>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        ) : (
          // Empty table
          <Card className="border-3 bg-light">
            <Card.Body className="px-4 py-5 d-flex flex-column align-items-center">
              <h4 className="mb-4 mt-0">Add workouts to this playlist</h4>

              <Form.Select
                className="border-dark"
                style={{ maxWidth: 350 }}
                value={selected_workout_id}
                onChange={handleWorkoutSelection}
              >
                <option
                  key="none"
                  value="none"
                  disabled
                >
                  Select a workout or compound wokout to add...
                </option>

                {renderNormalWorkoutsSelectGroupWithOptions()}
                {renderCompoundWorkoutsSelectGroupWithOptions()}
              </Form.Select>
            </Card.Body>
          </Card>
        )
      }
    </>
  )
}
