import { apiClient } from "../../../apiClient"

export type ApiUpdatePlaylistRequest = {
  patient_id: string,
  playlist_id: string,
  name: string,
  category_id: string,
  loop: boolean,
  shuffle: boolean,
  workouts: { id: string, type: string }[],
};

export const apiUpdatePlaylist = async ({ playlist_id, ...request }: ApiUpdatePlaylistRequest) => {
  const response = await apiClient.put(
    `/api/internal/playlists/${playlist_id}`,
    { playlist: request }
  );
  return response.data;
}
