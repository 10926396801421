import { apiClient } from "../../../apiClient"

type UpdatedOrNewWorkout = {
  id?: string,
  name: string,
  workout_category_id: string,
  max_difficulty: number,
  number_of_exercises: number,
  duration: number,
  selected_exercises: { id: string, emphasis: number }[],
  selected_games_ids: string[],
}

export type ApiUpdateCompoundWorkoutRequest = {
  compound_workout_id: string,
  patient_id: string,
  name: string,
  workout_category_id: string,
  workouts: UpdatedOrNewWorkout[],
};

export const apiUpdateCompoundWorkout = async ({ compound_workout_id, ...request }: ApiUpdateCompoundWorkoutRequest) => {
  const response = await apiClient.put(
    `/api/internal/compound_workouts/${compound_workout_id}`,
    {
      compound_workout: request
    }
  );
  return response.data;
}
