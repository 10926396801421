import { Workout } from "../../../../libs/types";
import { apiClient } from "../../../apiClient"

export type ApiCreateWorkoutRequest = {
  patient_id: string,
  name: string,
  workout_category_id: string,
  max_difficulty: number,
  number_of_exercises: number,
  duration: number,
  selected_exercises: { id: string, emphasis: number }[],
  selected_games_ids: string[],
};

export type ApiCreateWorkoutResponse = {
  workout: Workout;
}

export const apiCreateWorkout = async (request: ApiCreateWorkoutRequest): Promise<ApiCreateWorkoutResponse> => {
  const response = await apiClient.post(
    `api/internal/workouts`,
    { workout: request }
  );
  return response.data;
}
