import React from 'react';

export const RequiredFieldIndicator = () => {
  return (
    <>
      <span style={{ marginLeft: 1 }} aria-hidden="true" title="Required field">
        *
      </span>
      <span className="visually-hidden"> (required)</span>
    </>
  );
};
