import { Workout, WorkoutPatient } from "../../../../libs/types";
import { apiClient } from "../../../apiClient"

export type ApiGetAllWorkoutsResponse = {
  workouts: Workout[],
  workout_patients: WorkoutPatient[],
}

export const apiGetAllWorkouts = async (): Promise<ApiGetAllWorkoutsResponse> => {
  const response = await apiClient.get('api/internal/workouts');
  return response.data;
}
