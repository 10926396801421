import { Playlist } from "../../../../libs/types";
import { apiClient } from "../../../apiClient"

export type ApiCreatePlaylistRequest = {
  patient_id: string,
  name: string,
  category_id: string,
  loop: boolean,
  shuffle: boolean,
  workouts: { id: string, type: string }[],
};

export type ApiCreatePlaylistResponse = {
  playlist: Playlist;
}

export const apiCreatePlaylist = async (request: ApiCreatePlaylistRequest): Promise<ApiCreatePlaylistResponse> => {
  const response = await apiClient.post(
    `/api/internal/playlists`,
    { playlist: request }
  );

  return response.data;
}
