import $ from 'jquery';

$(function () {
  $('[data-exercise-difficulty-set-input]').on('input', function () {
    var total = [];
    $(this).closest('tr').find('[data-exercise-difficulty-set-input]').each(function (idx, elem) {
      var num = parseFloat($(this).val());
      if (!isNaN(num)) {
        total.push(num);
      }
      console.log($(this).val());
    });
    if (total.length == 0) {
      $(this).closest('tr').find('[data-exercise-difficulty-set-diff]').val(0.0);
    } else {
      $(this).closest('tr').find('[data-exercise-difficulty-set-diff]').val(total.reduce((a, b) => a + b, 0).toFixed(2));
    }
  });
});
