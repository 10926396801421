import React, { useEffect, useRef, useState } from "react";
import { Form, Spinner, Table } from "react-bootstrap";
import { Game } from "../../../libs/types";
import { useAsyncEffect } from "use-async-effect";
import { apiGetGameCompatibility } from "../../../api/resources/games/apiGetGameCompatibility";
import { CompoundWorkoutFormValues } from "./schema";
import { useFormContext, useWatch } from "react-hook-form";
import { useImmer } from "use-immer";
import { produce } from "immer";
import { useDebounce } from "use-debounce";
import { RequiredFieldIndicator } from "../../../libs/shared_components/RequiredFieldIndicator";
import classNames from "classnames";

type GamesSelectionTableProps = {
  games: Game[],
  canModify: boolean,
  forWorkoutFormPosition: number,
}

type GameCompatibilityMap = {
  /**
   * [game_id]: percentage
   */
  [game_id: string]: number,
}

export const GamesSelectionFormGroup = ({
  games,
  canModify,
  forWorkoutFormPosition,
}: GamesSelectionTableProps) => {
  const form = useFormContext<CompoundWorkoutFormValues>();

  const selected_exercises = useWatch({
    control: form.control,
    name: `workouts.${forWorkoutFormPosition}.selected_exercises`,
    defaultValue: [],
  });

  const selected_games_ids = useWatch({
    control: form.control,
    name: `workouts.${forWorkoutFormPosition}.selected_games_ids`,
    defaultValue: [],
  });

  const [debounced_selected_exercises] = useDebounce(selected_exercises, 1000, {
    equalityFn(left, right) {
      return left.length === right.length;
    },
  });

  const [loading_game_compatibility, setLoadingGameCompatibility] = useState<boolean>(true);
  const [game_compatibility_map, updateGameCompatibilityMap] = useImmer<GameCompatibilityMap>({});

  useEffect(() => {
    if (selected_exercises.length !== debounced_selected_exercises.length) {
      setLoadingGameCompatibility(true);
    }
  }, [selected_exercises]);

  useAsyncEffect(async () => {
    try {
      setLoadingGameCompatibility(true);

      if (!debounced_selected_exercises.length) {
        // No exercises selection, therefore games are not compatible with anything. Default to 0%.
        for (const game of games) {
          const game_id = game._id.$oid;

          updateGameCompatibilityMap((draft) => {
            draft[game_id] = 0
          });

          const is_game_selected = selected_games_ids.some((selected_game_id) => game_id === selected_game_id);
          if (is_game_selected) {
            onGameSelection(false, game_id);
          }
        }

        return;
      } else {
        // Fetch game compatibilities based on the selected exercises:
        const selected_exercises_ids = debounced_selected_exercises.map((exercise) => exercise.id);

        const gamesCompatibilityResponses = await Promise.all(
          games.map(game => apiGetGameCompatibility({
            game_id: game._id.$oid,
            selected_exercises_ids: selected_exercises_ids
          }))
        );

        games.forEach((game, index) => {
          const game_compatibility = gamesCompatibilityResponses[index].result;
          const game_id = game._id.$oid;

          updateGameCompatibilityMap((draft) => {
            draft[game_id] = game_compatibility
          });

          const is_game_selected = selected_games_ids.some((selected_game_id) => game_id === selected_game_id);
          if (!game_compatibility && is_game_selected) {
            onGameSelection(false, game_id);
          }
        });
      }
    } catch (error) {
      console.error('Failed to fetch game compatibility for selected exercises', { selected_exercises, error })

    } finally {
      setLoadingGameCompatibility(false)
    }
  }, [debounced_selected_exercises]);

  const isGameSelectable = (game: Game): boolean => {
    if (!canModify || loading_game_compatibility) {
      return false;
    }

    const compatibilityPercentage = getGameCompatibility(game);

    return !!compatibilityPercentage;
  }

  const getGameCompatibility = (game: Game): number => {
    return game_compatibility_map[game._id.$oid] ?? 0
  }

  const getGameCompatibilityAsPercentage = (game: Game): string => {
    return `${getGameCompatibility(game).toFixed(1)}%`;
  }

  const onGameSelection = (selected: boolean, game_id: string) => {
    const current_selected_games_ids = form.getValues().workouts[forWorkoutFormPosition].selected_games_ids;
    const updated_selected_games_ids = produce(current_selected_games_ids, (draft) => {
      const existing_index = draft.findIndex((selected_game_id) => selected_game_id === game_id);

      if (selected) {
        // Ensure that it's listed as selected.
        if (existing_index === -1) {
          // Only add if not already added.
          draft.push(game_id);
        }

      } else {
        // Ensure that is not listed as selected
        if (existing_index !== -1) {
          // Delete
          draft.splice(existing_index, 1);
        }
      }
    });

    form.setValue(`workouts.${forWorkoutFormPosition}.selected_games_ids`, updated_selected_games_ids, { shouldValidate: true, shouldTouch: true });
  }

  const getAllCompatibleGames = () => {
    return games.filter((game) => isGameSelectable(game));
  }

  const isAnyGameCompatible = (): boolean => {
    return !!getAllCompatibleGames().length;
  }

  const areAllCompatibleGamesSelected = (): boolean => {
    const selected_games_count = selected_games_ids.length;
    const compatible_games_count = getAllCompatibleGames().length;

    if (!selected_games_count || !compatible_games_count) {
      // No games selected
      return false;
    }

    // All games selected
    return selected_games_count === compatible_games_count;
  }

  const onAllCompatibleGamesSelection = (selected: boolean): void => {
    // Select / unselect all compatible games:
    for (const compatible_game of getAllCompatibleGames()) {
      onGameSelection(selected, compatible_game._id.$oid);
    }
  }

  const canSelectAllCompatibleGames = (): boolean => {
    if (!canModify || loading_game_compatibility) {
      return false;
    }

    return games.some((game) => isGameSelectable(game));
  }

  const hasCompatibleGamesButNoneSelected = (): boolean => {
    return isAnyGameCompatible() && !selected_games_ids.length
  }

  const noGamesAreCompatibleButHasSelectedExercises = (): boolean => {
    return !isAnyGameCompatible() && !!debounced_selected_exercises.length;
  }

  const shouldShowInvalidMessage = (): boolean => {
    if (loading_game_compatibility || !canModify) {
      return false;
    }

    const touched = !!form.formState.touchedFields.workouts?.[forWorkoutFormPosition]?.selected_games_ids;
    const hasValidationErrors = !!form.formState.errors?.workouts?.[forWorkoutFormPosition]?.selected_games_ids;

    return (touched && hasValidationErrors) || hasCompatibleGamesButNoneSelected() || noGamesAreCompatibleButHasSelectedExercises();
  }

  const renderRows = () => {
    const rows = games.map(game => {
      const is_game_selectable = isGameSelectable(game);
      const is_game_selected = selected_games_ids.some(
        (selected_game_id) => selected_game_id === game._id.$oid
      );

      return (
        <tr key={game._id.$oid} className={!is_game_selectable ? 'text-muted' : undefined}>
          <td>
            <Form.Check
              type="checkbox"
              disabled={!is_game_selectable}
              checked={is_game_selected}
              onChange={(event) => onGameSelection(event.currentTarget.checked, game._id.$oid)}
            />
          </td>
          <td>{game.name}</td>
          <td className="text-center">
            {loading_game_compatibility ? '...' : getGameCompatibilityAsPercentage(game)}
          </td>
        </tr>
      );
    });

    return rows;
  }

  return (
    <Form.Group>
      <Form.Label className={classNames('d-flex justify-content-between align-items-center', shouldShowInvalidMessage() ? 'is-invalid mb-0' : null)}>
        <div>
          Games<RequiredFieldIndicator />:
        </div>

        {loading_game_compatibility ? (
          <Spinner
            className="ms-2"
            animation="border"
            size="sm"
            style={{ width: 14, height: 14, borderWidth: 1.5 }}
          />
        ) : null}
      </Form.Label>
      <Form.Control.Feedback className="mb-2" type="invalid">
        {hasCompatibleGamesButNoneSelected() ? (
          'Please select at least one compatible game.'
        ) : noGamesAreCompatibleButHasSelectedExercises() ? (
          "The exercises you have selected don't form any compatible game. Please choose exercises that can be combined to create at least one compatible game for you to select."
        ) : null}
      </Form.Control.Feedback>

      <Table bordered>
        <thead>
          <tr>
            <th style={{ width: 1 }}>
              <Form.Check
                type="checkbox"
                disabled={!canSelectAllCompatibleGames()}
                checked={areAllCompatibleGamesSelected()}
                onChange={(event) => onAllCompatibleGamesSelection(event.currentTarget.checked)}
              />
            </th>
            <th style={{ width: '100%' }}>
              Game
            </th>
            <th style={{ minWidth: 140 }} className="text-center">
              Compatibility
            </th>
          </tr>
        </thead>
        <tbody>
          {renderRows()}
        </tbody>
      </Table>
    </Form.Group>
  )
}
