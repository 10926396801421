const TWENTY_FOUR_HOURS_IN_MINUTES = 1440;
const ONE_HOUR_IN_MINUTES = 60;

export const formatMinutes = (mins: number): string => {
  if (mins < 0) {
    return '0s'
  }

  if (mins < 1) {
    // Seconds
    const seconds = Math.round((mins * 60) % 60);
    return `${seconds}s`;
  }


  // Minutes, Hours and Days
  let remainingMinutes = mins;

  const days = Math.floor(remainingMinutes / TWENTY_FOUR_HOURS_IN_MINUTES);
  remainingMinutes %= TWENTY_FOUR_HOURS_IN_MINUTES;

  const hours = Math.floor(remainingMinutes / ONE_HOUR_IN_MINUTES);
  remainingMinutes %= ONE_HOUR_IN_MINUTES;

  const minutes = remainingMinutes;

  let formattedParts: string[] = [];

  if (days > 0) {
    formattedParts.push(`${days.toString()}d`);
  }

  if (hours > 0) {
    formattedParts.push(`${hours.toString()}h`);
  }

  if (minutes > 0) {
    formattedParts.push(`${minutes.toString()}m`);
  }

  return formattedParts.join(' ');
}
