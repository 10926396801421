import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { Alert, Button, Card, Form } from 'react-bootstrap';
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { CompoundWorkout, CompoundWorkoutWorkout, Exercise, ExerciseCategory, ExerciseDependency, ExerciseType, Game, Workout, WorkoutCategory } from '../../../libs/types';
import { WorkoutFormGroup } from './WorkoutFormGroup';
import { apiCreateCompoundWorkout } from '../../../api/resources/patients/compound_workouts/apiCreateCompoundWorkout';
import { compoundWorkoutFormSchema, CompoundWorkoutFormValues, convertCompoundWorkoutToFormValues } from './schema';
import { chain } from 'array-fns';
import { apiUpdateCompoundWorkout } from '../../../api/resources/patients/compound_workouts/apiUpdateCompoundWorkout';
import { RequiredFieldIndicator } from '../../../libs/shared_components/RequiredFieldIndicator';

type CompoundWorkoutFormProps = {
  /**
   * Indicates whether you're filling this form on behalf of the patient,
   * rather than being the actual patient. For example, this is `true` for
   * an admin creating a compound workout for a patient, and `false` when the actual patient
   * is creating their own compound workout
   */
  on_behalf_of_patient: boolean,
  patient_id: string,
  editing_details: {
    compound_workout: CompoundWorkout,
    compound_workout_workouts: CompoundWorkoutWorkout[],
    workouts: Workout[],
  } | undefined,
  games: Game[],
  exercises: Exercise[],
  exercise_types: ExerciseType[],
  workout_categories: WorkoutCategory[],
  exercise_categories: ExerciseCategory[],
  exercise_dependencies: ExerciseDependency[]
}

const CompoundWorkoutForm = ({
  on_behalf_of_patient,
  patient_id,
  editing_details,
  games,
  exercises,
  exercise_types,
  workout_categories,
  exercise_categories,
  exercise_dependencies
}: CompoundWorkoutFormProps) => {
  const form = useForm<CompoundWorkoutFormValues>({
    resolver: yupResolver(compoundWorkoutFormSchema),
    defaultValues:
      editing_details
        ? convertCompoundWorkoutToFormValues(editing_details.compound_workout, editing_details.workouts)
        : {
          name: '',
          workout_category_id: '',
          workouts: [],
        },
    mode: 'all',
    reValidateMode: 'onChange'
  });

  const onSaveAsCopy: SubmitHandler<CompoundWorkoutFormValues> = async (data) => {
    if (!editing_details) {
      return;
    }

    const not_modified_name = editing_details.compound_workout.name === data.name;
    if (not_modified_name) {
      // If copying the compound workout and its name was not modified, append (copy) into it so its easier to identify later.
      // - The user can edit this afterwards.
      data.name = `${data.name} (copy)`;
    }

    await submit(data, true);
  }

  const onSaveOrCreate: SubmitHandler<CompoundWorkoutFormValues> = async (data) => {
    await submit(data);
  }

  const submit = async (data: CompoundWorkoutFormValues, duplicate = false) => {
    try {
      let saved_compound_workout_id: string;

      if (duplicate || !editing_details) {
        // We're either duplicating this compound workout, that is, saving it as a new copy
        // or creating a fresh new compound workout.
        const response = await apiCreateCompoundWorkout({
          patient_id,
          name: data.name,
          workout_category_id: data.workout_category_id,
          workouts: data.workouts.map((workout) => ({
            id: workout.id,
            name: workout.name,
            workout_category_id: workout.category_id,
            max_difficulty: workout.difficulty,
            number_of_exercises: workout.number_of_exercises,
            duration: workout.duration,
            selected_exercises: workout.selected_exercises,
            selected_games_ids: workout.selected_games_ids,
          }))
        });

        saved_compound_workout_id = response.compound_workout._id.$oid;

      } else if (editing_details) {
        // We're editing an existing compound workout, so save the changes.
        const response = await apiUpdateCompoundWorkout({
          patient_id,
          compound_workout_id: editing_details.compound_workout._id.$oid,
          name: data.name,
          workout_category_id: data.workout_category_id,
          workouts: data.workouts.map((updated_or_new_workout) => ({
            id: updated_or_new_workout.id,
            name: updated_or_new_workout.name,
            workout_category_id: updated_or_new_workout.category_id,
            max_difficulty: updated_or_new_workout.difficulty,
            number_of_exercises: updated_or_new_workout.number_of_exercises,
            duration: updated_or_new_workout.duration,
            selected_exercises: updated_or_new_workout.selected_exercises,
            selected_games_ids: updated_or_new_workout.selected_games_ids,
          }))
        });

        saved_compound_workout_id = response.compound_workout._id.$oid;
      } else {
        return;
      }

      let next_page_path: string;
      if (duplicate) {
        // Navigate to the editing page of the duplicated compound workout:
        if (on_behalf_of_patient) {
          // On behalf of the patient (e.g. an admin creating for a patient):
          next_page_path = `/patients/${patient_id}/compound_workouts/${saved_compound_workout_id}/edit`;
        } else {
          // As the actual patient:
          next_page_path = `/me/compound_workouts/${saved_compound_workout_id}/edit`;
        }
      } else {
        if (on_behalf_of_patient) {
          // On behalf of the patient (e.g. an admin creating for a patient):
          next_page_path = `/patients/${patient_id}/compound_workouts/`;
        } else {
          // As the actual patient:
          next_page_path = `/me/compound_workouts/`;
        }
      }

      window.location.pathname = next_page_path;

    } catch (error) {
      alert('Failed to create: ' + JSON.stringify(error));
    }
  };

  return (
    <div>
      <FormProvider {...form}>
        <form noValidate>
          <Card>
            <Card.Header>
              Details
            </Card.Header>
            <Card.Body>
              <Form.Group className="mb-3">
                <Form.Label>Name<RequiredFieldIndicator />:</Form.Label>
                <Form.Control
                  {...form.register('name')}
                  type="text"
                  isValid={form.formState.dirtyFields.name && !form.formState.errors.name}
                  isInvalid={form.formState.touchedFields.name && !!form.formState.errors.name}
                />
                <Form.Control.Feedback type='invalid'>{form.formState.errors.name?.message}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Category<RequiredFieldIndicator />:</Form.Label>
                <Form.Select
                  {...form.register('workout_category_id')}
                  isValid={form.formState.dirtyFields.workout_category_id && !form.formState.errors.workout_category_id}
                  isInvalid={form.formState.touchedFields.workout_category_id && !!form.formState.errors.workout_category_id}
                >
                  {workout_categories.map((workout_category) => (
                    <option key={workout_category._id.$oid} value={workout_category._id.$oid}>
                      {workout_category.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type='invalid'>{form.formState.errors.workout_category_id?.message}</Form.Control.Feedback>
              </Form.Group>
            </Card.Body>
          </Card>

          <WorkoutFormGroup
            on_behalf_of_patient={on_behalf_of_patient}
            patient_id={patient_id}
            games={games}
            exercises={exercises}
            exercise_types={exercise_types}
            workout_categories={workout_categories}
            exercise_categories={exercise_categories}
            exercise_dependencies={exercise_dependencies}
          />

          <Card>
            <Card.Body className="text-end">
              {editing_details ? (
                <Button onClick={form.handleSubmit(onSaveAsCopy)} className="me-2" variant="secondary" disabled={!form.formState.isValid}>
                  Save as copy
                </Button>
              ) : null}

              <Button onClick={form.handleSubmit(onSaveOrCreate)} variant="primary" type="submit" disabled={!form.formState.isValid}>
                {editing_details ? 'Save' : 'Create'}
              </Button>
            </Card.Body>
          </Card>
        </form>
      </FormProvider>
    </div>
  )
}

export default CompoundWorkoutForm;
