import axios from "axios";

const extractCsrfTokenFromCurrentPage = (doc: Document = window.document) => {
  const token = doc.querySelector('meta[name="csrf-token"]')?.getAttribute('content');
  return token;
}

const apiClient = axios.create({
  baseURL: '/',
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': extractCsrfTokenFromCurrentPage(),
  }
});

export { apiClient };
