import { CompoundWorkout } from "../../../../libs/types";
import { apiClient } from "../../../apiClient"

type NewWorkout = {
  id?: string,
  name: string,
  workout_category_id: string,
  max_difficulty: number,
  number_of_exercises: number,
  duration: number,
  selected_exercises: { id: string, emphasis: number }[],
  selected_games_ids: string[],
}

export type ApiCreateCompoundWorkoutRequest = {
  patient_id: string,
  name: string,
  workout_category_id: string,
  workouts: NewWorkout[],
};

export type ApiCreateCompoundWorkoutResponse = {
  compound_workout: CompoundWorkout,
}

export const apiCreateCompoundWorkout = async (request: ApiCreateCompoundWorkoutRequest): Promise<ApiCreateCompoundWorkoutResponse> => {
  const response = await apiClient.post(
    `/api/internal/compound_workouts`,
    {
      compound_workout: request
    }
  );

  return response.data;
}
