import { Controller } from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static targets = ['input', 'compatibility', 'button'];

  /**
   * Set compatibility %'s for Game instances by default,
   * i.e. when entering the page.
   */
  connect() {
    this.updateExerciseSelection();
  }

  /**
   * Invoke compatibility %'s updates any time a change (event)
   * occurs (e.g. when any exercise selection item is clicked).
   *
   * @param {*} event - click/unclick on exercise input.
   */
  reCalculateGameCompatibility(event) {
    const workoutColumnIndex = event.currentTarget?.dataset?.workoutColumnIndex || event.detail?.workoutColumnIndex;
    this.updateExerciseSelection(workoutColumnIndex);
  }

  /**
   * Handle the update on exercise selections receiving
   * new exercise compatibilities for all Game objects.
   * 
   * @param {string|undefined} workoutColumnIndex - Target a specific workout column
   */
  updateExerciseSelection(workoutColumnIndex = undefined)  {
    const promises = [];
    const selectedExercisesIds = this.inputTargets
      .filter(input => input.checked && input.dataset.workoutColumnIndex === workoutColumnIndex)
      .map(input => input.dataset.exerciseId);

    this.compatibilityTargets
      .filter(target => target.dataset.workoutColumnIndex === workoutColumnIndex)
      .forEach(compatibilityTarget => {
        const gameId = compatibilityTarget.dataset.id;
  
        if (gameId && selectedExercisesIds) {
          const resultPromise = this.getExercisesToGameCompatibility(gameId, selectedExercisesIds);
          promises.push(resultPromise);
        }
      });

    // Await for all the results so that they are updated on the screen at once.
    Promise.all(promises)
      .then(results => {
        this.setCompatibilityResults(results, workoutColumnIndex);
        this.verifyResultsValidity(results);
        
      })
      .catch(error => {
        this.dispatch('flash', { detail: { message: error } });
      });
  }

  /**
   * Set the new compatibility values on target DOM elements.
   * 
   * @param {*} results - new compatibility results.
   * @param {string|undefined} workoutColumnIndex - Target a specific workout column
   */
  setCompatibilityResults(results, workoutColumnIndex) {
    this.compatibilityTargets
      .filter(target => target.dataset.workoutColumnIndex === workoutColumnIndex)
      .forEach((target, i) => {
        const result = results[i].toFixed(1);
        target.textContent = `${result}%`;
      });

    // Notify that the compatibility has been updated
    const event = new CustomEvent("compatibility-updated", {
      detail: {
        workoutColumnIndex: workoutColumnIndex
      }
    });
    window.dispatchEvent(event);
  }

  /**
   * Verify validity of the results, if any compatibility value equals
   * zero, then the form cannot be submitted and a tooltip is added.
   * 
   * @param {*} results - new compatibility results.
   */
  verifyResultsValidity(results) {
    const invalidResults = results.some((result) => result === 0.0);

    if (invalidResults) {
      this.buttonTarget.setAttribute('disabled', true);
    } else {
      this.buttonTarget.removeAttribute('disabled');
    }
  }

  /**
   * Retrieve the compatibility %'s for selected game and exercises 
   * through AJAX request that uses route from GamesController.
   * 
   * @param {*} gameId - current game ID.
   * @param {*} selectedExercisesIds - array of IDs of selected exercises.
   */
  getExercisesToGameCompatibility(gameId, selectedExercisesIds) {
    const url = `/games/${gameId}/compatibility`;
    const data = {
      selected_exercises_ids: Array.isArray(selectedExercisesIds)
        ? selectedExercisesIds
        : [selectedExercisesIds],
    };

    return new Promise((resolve, reject) => {
      Rails.ajax({
        url: url,
        type: 'post',
        data: JSON.stringify(data),
        dataType: 'json',
        success: response => {
          resolve(response.result);
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }
}
