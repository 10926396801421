import * as yup from 'yup';

export const playlistFormSchema = yup.object({
  id: yup.string().optional(),
  name: yup.string().trim().required('Please enter a name.').label('Name'),
  category_id: yup.string().required('Please select a category.').label('Category'),
  loop: yup.boolean().default(false),
  shuffle: yup.boolean().default(false),
  selected_workouts: yup.array().of(
    yup.object().shape({
      id: yup.string().required(),
      type: yup.string().required()
    })
  ).min(1).required(),
}).required();

export type PlaylistFormValues = yup.InferType<typeof playlistFormSchema>;
